import { Link } from "react-router-dom";

function Breadcrumbs(props) {

    const length = props.element.items.length;

    return(
        <ul className="list-unstyled list-inline p-0 m-0">
            <li className="list-inline-item">
                <img src={props.element.icon} />
            </li>

            {props.element.items.map((item, index) => (

                <li className="list-inline-item" key={`breadcrumbItem_${index.toString()}`}>
                    <Link to={item.url} className={`text-decoration-none text-dark ${((index + 1 != length)) ? 'fw-bold' : ''}`}>
                        {item.title}
                        {(index + 1 == length) ? '' : ' / '}
                    </Link>
                </li>

            ))}
        </ul>
    );
}

export default Breadcrumbs;