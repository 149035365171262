import { useState, useEffect } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import api from '../services/api';
import { useAuth } from '../hooks/AuthProvider';

import SearchMagnifier from '../assets/images/search-magnifier.svg';
import fileAdd from '../assets/images/file-add.svg';

function MyCatalog() {

    const { t, i18n } = useTranslation();
    const [items, setItems] = useState([]);
    const [value, setValue] = useState('');
    const [loader, setLoader] = useState(true);
    const { cookies } = useAuth();

    const getData = async () => {
        const res = await api.get('/api/seller/xls', {
            withCredentials: false,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${cookies.token}`
            }
        });

        setItems(res.data);
    }

    useEffect(() => {
        
        getData();

    }, []);

    const colors = {
        1: 'success',
        2: 'warning',
        3: 'danger'
    }

    const statuses = {
        1: t('updated'),
        2: t('tobeUpdated'),
        3: t('expired')
    }

    return (
        <Container fluid className="p-5">
            <Row>
                <Col xs={12}>
                    <h3 className="fw-bold mb-3 p-0">
                        {t('myFiles')}
                    </h3>
                </Col>
            </Row>

            <Row>
                <Col xs={12} md={8}>
                    <Form>
                        <Form.Group className="position-relative">
                            <Form.Control
                                type="text"
                                className="grey-input ps-5 rounded-2 fw-normal fs-6 py-3"
                                onChange={(e) => setValue(e.target.value)}
                            />
                            <img className="position-absolute start-0 ms-3 top-50" style={{transform: "translateY(-50%)"}} src={SearchMagnifier} />
                        </Form.Group>
                    </Form>
                </Col>

                <Col xs={12} md={4}>
                    <Link to="#" className="btn btn-secondary w-100 p-3">
                        <img className="me-3" src={fileAdd} />
                        {t('addNewPart')}
                    </Link>
                </Col>
            </Row>

            <div className="px-3 mt-3">
                {(items.length > 0) ?

                    items.map((item, index) => (
                        <Row className={`border mb-3 rounded-2 p-3 border-success`} key={index}>
                            <Col xs={12} md={3} className="fw-bold d-flex align-items-center">
                                {item.file_name}
                            </Col>

                            <Col xs={12} md={1} className="fw-bold d-flex align-items-center">
                                {item.count}
                            </Col>

                            <Col xs={12} md={2} className={`fst-italic d-flex align-items-center text-success`}>
                                {'განახლებული'}
                            </Col>

                            <Col xs={12} md={3} className="d-flex align-items-center">
                                {t('lastUpdated') + ' ' + item.updated_at}
                            </Col>

                            <Col xs={12} md={3} className="d-flex align-items-center">
                                <Link to={`/file/${item.id}`} className={`btn w-100 btn-success`}>
                                    {t('seeDetails')}
                                </Link>
                            </Col>
                        </Row>
                    ))

                : t('No files are found') }
            </div>

            <Row className="mt-5">
                <Col>
                    <Link to='/catalog/upload' className="btn btn-success p-3 w-100">
                        {t('addPartsWithFile')}
                    </Link>
                </Col>

                <Col>
                    <Link to='' className="btn btn-outline-success p-3 w-100">
                        {t('addIndividualFile')}
                    </Link>
                </Col>
            </Row>
        </Container>
    )
}

export default MyCatalog