import { useState } from "react";
import { Col, Container, Row, Button, Form, ProgressBar } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useAuth } from '../hooks/AuthProvider';
import api from '../services/api';
import downloadXls from '../assets/images/download-xls.svg';
import uploadXls from '../assets/images/upload-xls.svg';
import { useNavigate } from "react-router-dom";


function Upload() {

    const { t, i18n } = useTranslation();

    const [key, setKey] = useState('originalNew');
    const [fileName, setFileName] = useState('');
    const [fileSelected, setFileSelected] = useState(false);
    const [uploadStarted, setUploadStarted] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [file, setFile] = useState(null);
    const { cookies } = useAuth();
    const navigate = useNavigate();


    const tabs = ['originalNew', 'replicaNew', 'originalUsed'];

    const handleFile = (e) => {
        
        if (e.target.files.length > 0 && e.target.files[0].name) {
            setFileName(e.target.files[0].name);
            setFileSelected(true);
            setFile(e.target.files[0]);
        } else {
            setFileName('');
            setFileSelected(false);
            setFile(null);
        }

        setErrorMessage(null);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let formData = new FormData();
        formData.append('file', file);
        formData.append('type', key);

        setUploadStarted(true);

        const res = await api.post('/api/seller/xls/upload', formData, {
            withCredentials: false,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${cookies.token}`
            }
        }).catch(function (error) {
            setFile(null);
            setFileName('');
            setFileSelected(false);
            setUploadStarted(false);
            setErrorMessage(t('failedFileUpload'));
        });

        if (res.status == 201 && res.data.id) {
            navigate('/file/' + res.data.id);
        } else {
            setFile(null);
            setFileName('');
            setFileSelected(false);
            setUploadStarted(false);
            setErrorMessage(t('failedFileUpload'));
        }

    }

    return (
        <Container fluid className="p-5">
            <Row>
                {tabs.map((item, index) => (
                    <Col
                        className={`tab ${(item == key) ? 'active' : ''}`}
                        onClick={(e) => setKey(item)}
                        key={index}
                    >
                        {t('newOriginalParts')}
                    </Col>
                ))}
            </Row>

            <Row>
                <Col className="tab-content p-3">
                    <Form onSubmit={handleSubmit} className={(uploadStarted) ? 'd-none' : 'd-block'}>
                        <Form.Group>
                            <Form.Label className="w-100" style={{cursor: "pointer"}}>
                                <Form.Control type="file" className="opacity-0 position-absolute" onChange={handleFile} />
                                <div className={`upload-inner-box text-center ${fileSelected ? 'd-none' : 'd-inline-block'}`}>
                                    <h6 className="fw-bold">{t('uploadPutFileHere')}</h6>
                                    <p>{t('downloadCorrectFile')}</p>
                                </div>

                                <div className={`pt-4 text-center ${fileSelected ? 'd-block' : 'd-none'}`}>
                                    <img className="mb-3" src={uploadXls} />
                                    <h5 className="text-success fw-bold">
                                        {fileName}
                                    </h5>
                                </div>
                            </Form.Label>

                            <p className={`py-3 text-danger ${(errorMessage) ? 'd-block' : 'd-none'}`}>{errorMessage}</p>

                            <Button type="submit" variant="outline-secondary" className={`p-3 ${fileSelected ? 'd-inline-block' : 'd-none'}`}>
                                {t('uploadFile')}
                            </Button>

                            <div className="my-4">
                                <a target="_blank" href={`${key}.xlsx`}>
                                    <img src={downloadXls} />
                                </a>
                            </div>
                        </Form.Group>
                    </Form>

                    <Row className={`py-4 ${(uploadStarted) ? 'd-flex' : 'd-none'}`}>
                        <Col xs={12} md={6} className="text-center mx-auto">
                            <img className="mb-3" src={uploadXls} />
                            <p>{t('fileInProgress')}</p>
                            <h5 className="text-success fw-bold">
                                {fileName}
                            </h5>
                            <ProgressBar now={25} variant="success" animated style={{height: "2rem"}} className="mt-3" />
                            <p className="mt-4">{t('pleaseWait')}</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default Upload