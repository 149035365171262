import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Button, Col, Container, Row, Table, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useAuth } from '../hooks/AuthProvider';
import { useNavigate } from "react-router-dom";
import api from '../services/api';
import { ThreeDots } from "react-loader-spinner";

import noImg from '../assets/images/no-img.svg';

function File() {

    const { t, i18n } = useTranslation();

    const { fileId } = useParams();
    const [xlsId, setXlsId] = useState(null);
    const [filename, setFilename] = useState('');
    const [error, setError] = useState(null);
    const [sheets, setSheets] = useState([]);
    const [data, setData] = useState([]);
    const [sheetSelected, setSheetSelected] = useState(null);
    const { cookies } = useAuth();
    const navigate = useNavigate();

    const getXls = async () => {

        const res = await api.get('/api/seller/xls/' + fileId, {
            withCredentials: false,
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${cookies.token}`
            }
        }).catch(function (error) {
            navigate('/404');
        });

        if (res.status == 200 && res.data) {

            setXlsId(res.data.id);
            setFilename(res.data.file_name);

        } else {
            navigate('/404');
        }

    }

    const getData = async () => {

        const res = await api.get('/api/seller/stock/' + xlsId, {
            withCredentials: false,
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${cookies.token}`
            }
        }).catch(function (error) {
            setError();
        });

        if (res.status == 200 && res.data) {

            setSheets(res.data.sheets);
            setData(res.data.stock);
            setSheetSelected(res.data.sheets[0]);

        } else {
            setError();
        }

    }

    useEffect(() => {

        getXls();

    }, []);

    useEffect(() => {

        console.log(xlsId);

        if (xlsId) {
            getData();
        }

    }, [xlsId]);

    const handeSheetChange = (e) => {
        setSheetSelected(e.currentTarget.value);

        console.log(sheetSelected);
    }

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Container fluid className="p-5">

<Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body className="p-5 text-center">
        <ThreeDots 
                            height="32" 
                            width="32" 
                            radius="9"
                            color="#000" 
                            ariaLabel="three-dots-loading"
                        />
        </Modal.Body>
      </Modal>

            <Row>
                <Col xs={12}>
                    <h4 className="fw-bold mb-3 p-0">
                        {t('addPartsWithXls')}
                    </h4>
                </Col>

                <Col xs={12}>
                    <h5 className="fw-bold text-success">
                        {filename}
                    </h5>

                    <p className="text-danger w-50 mt-3">
                        {t('photoRequiredWhenUsed')}
                    </p>

                    <p className="mt-4">
                        {t('addSelectedPages')}
                    </p>
                </Col>
            </Row>

            {sheets ? 
                <Row className="mb-5">
                    {sheets.map((sheet, index) => (
                        <Col className="d-flex align-items-center" key={'sheet_' + index}>
                            <input
                                name="sheet"
                                type="radio"
                                value={sheet}
                                id={'sheet_' + index}
                                onChange={handeSheetChange}
                                checked={(sheetSelected && sheetSelected == sheet) ? true : false}
                            />
                            <label className="ms-2 bg-grey p-1 rounded" htmlFor={'sheet_' + index}>{sheet}</label>
                        </Col>
                    ))}
                </Row>
            : ''}

            {data && sheetSelected ? 
            <Row>
                <Col>
                    <Table responsive bordered>
                        <thead className="bg-success">
                            <tr>
                                <th className="py-3" style={{borderRadius: "0.375rem 0 0 0"}}>&nbsp;</th>
                                <th className="py-3">&nbsp;</th>
                                <th className="py-3">ბრენდი</th>
                                <th className="py-3">დასახელება</th>
                                <th className="py-3">ნაწილის ID</th>
                                <th className="py-3">ქროსები</th>
                                <th className="py-3">წონა</th>
                                <th className="py-3">გარანტია</th>
                                <th className="py-3">მომზადებს დრო</th>
                                <th className="py-3">მარაგი</th>
                                <th className="py-3" style={{borderRadius: "0 0.375rem 0 0"}}>ფასი</th>
                            </tr>
                        </thead>

                        <tbody className="bg-grey">
                            {data[sheetSelected].map((part, index) => (
                                <tr key={'part_' + index}>
                                    <th className="align-middle">{index + 1}</th>
                                    <th className="text-center">
                                        <Button variant="link" onClick={handleShow}>
                                            <img src={noImg} />
                                        </Button>
                                    </th>
                                    <th className="align-middle">{part.brand}</th>
                                    <th className="align-middle">{part.part_name}</th>
                                    <th className="align-middle">{part.part_id}</th>
                                    <th className="align-middle">{(JSON.parse(part.cross).join(', '))}</th>
                                    <th className="align-middle">{part.weight}</th>
                                    <th className="align-middle">{part.warranty}</th>
                                    <th className="align-middle">{part.preparation}</th>
                                    <th className="align-middle">{part.stock}</th>
                                    <th className="align-middle">{part.price}</th>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            : ''}
        </Container>
    );
}

export default File;