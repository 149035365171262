import { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'react-bootstrap';
import logo from '../assets/images/logo-yellow.svg';
import arrowLeft from '../assets/images/arrow-l.svg';
import arrowRight from '../assets/images/arrow-r.svg';
import Header from "./Header";
import MenuItems from "./MenuItems";

function Layout({ page, breadcrumbs, component }) {

    const [asideWidth, setAsideWidth] = useState('350px');
    const [asideOpen, setAsideOpen] = useState(true);
    const [openAsideVisible, setOpenAsideVisible] = useState(false);
    const { t, i18n } = useTranslation();

    const handleClose = (e) => {
        setAsideWidth('75px');
        setAsideOpen(false);
        setOpenAsideVisible(true);
    }

    const handleOpen = (e) => {
        setAsideWidth('350px');
        setAsideOpen(true);
        setOpenAsideVisible(false);
    }

    return (
        <>
            <aside style={{width: asideWidth}} className="aside">
                <div className={`h-75 py-4 ${asideOpen ? 'px-4' : 'px-0'}`}>
                    <div className="d-flex align-items-center ">
                        <a className="text-center" href="/"><img className="w-75" alt="viaro" src={logo} /></a>
                        <h5 className={`text-uppercase fw-bold mt-2 ${asideOpen ? 'd-block' : 'd-none'}`}>{t('autoPartsIS')}<br/>{t('digitalMarket')}</h5>
                    </div>

                    <Button className="p-0 aside-close">
                        <img src={arrowLeft} onClick={handleClose} />
                    </Button>

                    <MenuItems page={page} />
                </div>
            </aside>

            <Button className="p-0 aside-open">
                <img src={arrowRight} onClick={handleOpen} />
            </Button>

            <main style={{marginLeft: asideWidth}} className="main">
                <Header breadcrumbs={breadcrumbs} />
                {component}
            </main>
        </>
    );
}

export default Layout