import { useTranslation } from 'react-i18next';
import menuCatalog from '../assets/images/menu-catalog.svg';
import menuCar from '../assets/images/menu-car.svg';
import menuOrders from '../assets/images/menu-orders.svg';
import menuArchive from '../assets/images/menu-archive.svg';
import menuFinances from '../assets/images/menu-finances.svg';
import menuHelp from '../assets/images/menu-help.svg';
import MenuItem from './MenuItem';

function MenuItems(props) {

    const { t, i18n } = useTranslation();

    const items = [
        {
            url: 'catalog',
            image: menuCatalog,
            title: t('myCatalog')
        },
        {
            url: 'vehicles',
            image: menuCar,
            title: t('myVehicles')
        },
        {
            url: 'orders',
            image: menuOrders,
            title: t('myOrders')
        },
        {
            url: 'archive',
            image: menuArchive,
            title: t('ordersArchive')
        },
        {
            url: 'finances',
            image: menuFinances,
            title: t('myFinances')
        },
        {
            url: 'help',
            image: menuHelp,
            title: t('help')
        }
    ];

    return (
        <ul className="list-unstyled mt-5 ps-2">
            {items.map((item, index) => (
                <MenuItem
                    url={'/' + item.url}
                    image={item.image}
                    value={item.title}
                    active={(props.page == item.url) ? true : false}
                    key={`menuItems_${index.toString()}`}
                />
            ))}
        </ul>
    )
}

export default MenuItems